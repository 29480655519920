(function () {
  'use strict';

  angular.module('app.layout').controller('LayoutController', LayoutController);
  function LayoutController($rootScope, $state, CommunicationService, SPORT_ID) {
    this.isOnPlatformPage = function () {
      return $state.includes('root.platform');
    };

    this.isOnHomePage = function () {
      return $state.includes($rootScope.getDefaultStateForModule('countries'));
    };

    this.isInTaggingMode = function () {
      return $rootScope.isInTaggingMode;
    };

    this.hasInboxUnread = function () {
      return CommunicationService.hasInboxUnread;
    };

    this.isHockey = function () {
      return (
        (parseInt(localStorage.getItem('scActiveSport')) || SPORT_ID.HOCKEY) === SPORT_ID.HOCKEY
      );
    };
  }
})();
