(function () {
  angular.module('app.layout').controller('HeaderController', HeaderController);

  function HeaderController(
    $scope,
    $state,
    Tabs,
    $rootScope,
    SCConfiguration,
    UserService,
    $http,
    ScheduledMaintenance
  ) {
    this.$onInit = function () {
      $http.get(SCConfiguration.getEndpoint() + '/api/player-rating/template').then((response) => {
        const { activeTemplate } = response.data;
        this.hasOrganisationAccess =
          // allowing SportContract to have access
          $rootScope.$scNeedAnyPermissions(['company-organization:58ef6e011270d16a5e259e4a:*']) ||
          // Any agencies organization should have access
          $rootScope.$scIsOrganizationOfType('agency-organization') ||
          // team organization and have player rating module
          ($rootScope.$scIsOrganizationOfType('team-organization') &&
            $rootScope.$scHasAccountPermissions('player-rating')) ||
          (activeTemplate &&
            activeTemplate.playerCanRate &&
            UserService.getActiveAccount().isPlayer);
      });

      this.nextScheduledMaintenance = ScheduledMaintenance.getNextScheduledMaintenance();
    };

    this.isScheduledMaintenanceBannerVisible = function () {
      return ScheduledMaintenance.isScheduledMaintenanceBannerVisible;
    };

    this.tabs = Tabs.getTabs();

    this.isSearchVisible = function () {
      return !$state.includes('root.calendar');
    };

    this.goHome = function () {
      Tabs.goHome();
    };

    this.goBack = function () {
      Tabs.goBack();
    };

    this.newTab = function () {
      Tabs.newForegroundTabWithHome();
    };

    this.closeTab = function ($event, tab) {
      Tabs.closeTab(tab);
      $event.preventDefault();
      $event.stopPropagation();
    };
  }
})();
